import type { FC } from 'react';
import CircularProgress from '../../components/CircularProgress/CircularProgress';
import styles from './styles.module.less';

const PageLoading: FC = () => (
  <div data-testid="page-loading" className={styles.container}>
    <div className={styles.wrapper}>
      <CircularProgress />
    </div>
  </div>
);

export default PageLoading;
