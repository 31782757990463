import type { CircularProgressProps } from '@material-ui/core';
import { CircularProgress as MuiCircularProgress, makeStyles } from '@material-ui/core';
import type { FC } from 'react';

const styles = makeStyles({
  root: {
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: 'unset !important',
  },
  bottom: {
    opacity: 0.25,
  },
  top: {
    animationDuration: '550ms',
    position: 'absolute',
  },
});

const DEFAULT_SIZE = 48;

const getSize = (size: number | string | undefined) => {
  if (typeof size === 'string') {
    return size === 'small' ? 16 : 48;
  }

  if (typeof size === 'number') {
    return size;
  }

  return DEFAULT_SIZE;
};

const CircularProgress: FC<CircularProgressProps> = ({ className, style, ...props }) => {
  const classes = styles();

  const size = getSize(props.size);

  const commonProps = {
    size,
    thickness: 7,
  };

  return (
    <div
      data-testid="circular-progress"
      className={classes.root}
      style={{ width: size, height: size, ...(style || {}) }}
    >
      <MuiCircularProgress
        data-testid="cp-bottom"
        variant="determinate"
        className={`${classes.bottom} ${className ?? ''}`}
        {...props}
        {...commonProps}
        value={100}
      />
      <MuiCircularProgress
        data-testid="cp-top"
        variant="indeterminate"
        disableShrink
        className={`${classes.top} ${className ?? ''}`}
        {...props}
        {...commonProps}
        style={{ left: 0 }}
      />
    </div>
  );
};

export default CircularProgress;
