import { lazy } from 'react';

const SurveyPage = lazy(() => import('./pages/surveys/survey'));

export type PropifyRoute = {
  path: string;
  component?: Function;
  routes?: PropifyRoute[];
  redirect?: string;
  props?: Record<string, unknown>;
};

export const routes: PropifyRoute[] = [
  {
    path: '/surveys',
    component: SurveyPage,
  },
];
